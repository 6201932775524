<template>
    <div class="relative">
        <svg
            class="spin"
            v-bind="$attrs" viewBox="-25.25 -25.25 252.5 252.5" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle r="91" cx="101" cy="101" fill="transparent" stroke="#ffffff" stroke-width="16px" stroke-dasharray="571.48px" stroke-dashoffset="0"></circle>
            <circle r="91" cx="101" cy="101" stroke="#292929" stroke-width="30" stroke-linecap="round" stroke-dashoffset="429px" fill="transparent" stroke-dasharray="571.48px"></circle>
        </svg>
    </div>
</template>
<style scoped>
.spin {
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
</style>
<script setup lang="ts">
</script>
